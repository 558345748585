var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('el-page-header',{attrs:{"content":_vm.title},on:{"back":function($event){return _vm.$router.go(-1)}}}),_c('div',{staticClass:"text-center mt-3 mb-3"},[_c('el-pagination',{attrs:{"background":"","page-size":_vm.pageSize,"current-page":_vm.page,"total":_vm.resultsTotal,"pager-count":10,"layout":"prev, pager, next"},on:{"current-change":_vm.setPage}})],1),_c('el-table',{staticClass:"mt-4",staticStyle:{"min-width":"100%"},attrs:{"data":_vm.teachers,"border":""}},[_c('el-table-column',{attrs:{"label":"Name"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('router-link',{attrs:{"to":{
            name: 'AdminViewUser',
            params: {
              id: scope.row.id
            }
          }}},[_c('b',[_vm._v(" "+_vm._s(_vm.user.displayName(scope.row.first_name, scope.row.last_name))+" ")])])]}}])}),_c('el-table-column',{attrs:{"prop":"email","label":"Email","width":"350"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('b',[_vm._v(" "+_vm._s(scope.row.email)+" ")])]}}])}),_c('el-table-column',{attrs:{"label":"Role","min-width":"80"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_vm._l((scope.row.roles),function(role,index){return _c('span',{key:index},[_c('el-tooltip',{staticClass:"item",attrs:{"effect":"dark","content":"Company Admin","placement":"top"}},[(role.name === 'company-admin')?_c('el-tag',{staticClass:"mr-2",attrs:{"type":"success"}},[_c('i',{staticClass:"fas fas fa-building"})]):_vm._e()],1),_c('el-tooltip',{staticClass:"item",attrs:{"effect":"dark","content":"Teacher","placement":"top"}},[(role.name === 'teacher')?_c('el-tag',{staticClass:"mr-2",attrs:{"type":"success"}},[_c('i',{staticClass:"fas fa-chalkboard-teacher"})]):_vm._e()],1)],1)}),_c('el-tooltip',{staticClass:"item",attrs:{"effect":"dark","content":"Student","placement":"top"}},[_c('el-tag',{attrs:{"type":"success"}},[_c('i',{staticClass:"fas fa-user"})])],1)]}}])}),_c('el-table-column',{attrs:{"label":"Date Registered","width":"150"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_vm._v(" "+_vm._s(_vm.instant.formatGoogleTime(scope.row.created_at))+" ")]}}])}),_c('el-table-column',{attrs:{"label":"IP","width":"200"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [(scope.row.last_ip)?_c('span',[(scope.row.last_iso)?[_vm._v(" ("+_vm._s(scope.row.last_iso)+") ")]:_vm._e(),_vm._v(" "+_vm._s(scope.row.last_ip)+" ")],2):_vm._e()]}}])}),_c('el-table-column',{attrs:{"label":"Logs","width":"80"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('router-link',{attrs:{"to":{
            name: 'AdminUsersLogs',
            query: {
              search: scope.row.email
            }
          }}},[_c('el-button',{attrs:{"type":"success","size":"mini"}},[_c('i',{staticClass:"fa fa-eye"})])],1)]}}])})],1),_c('div',{staticClass:"text-center mt-3"},[_c('el-pagination',{attrs:{"background":"","page-size":_vm.pageSize,"current-page":_vm.page,"total":_vm.resultsTotal,"pager-count":10,"layout":"prev, pager, next"},on:{"current-change":_vm.setPage}})],1),_c('el-dialog',{attrs:{"title":"Create New Company","visible":_vm.showCompanyModal,"width":"50%"},on:{"update:visible":function($event){_vm.showCompanyModal=$event}}},[_c('el-form',{attrs:{"label-width":"100px"}},[_c('el-form-item',{attrs:{"label":"Name"}},[_c('el-input',{model:{value:(_vm.newCompany.name),callback:function ($$v) {_vm.$set(_vm.newCompany, "name", $$v)},expression:"newCompany.name"}})],1),_c('el-form-item',{attrs:{"label":"User"}},[_c('el-select',{staticStyle:{"width":"100%"},attrs:{"filterable":"","remote":"","reserve-keyword":"","placeholder":"Email / Name","remote-method":_vm.searchTeachers},model:{value:(_vm.newCompany.user_id),callback:function ($$v) {_vm.$set(_vm.newCompany, "user_id", $$v)},expression:"newCompany.user_id"}},_vm._l((_vm.teachers),function(teacher,index){return _c('el-option',{key:teacher.id,attrs:{"label":`${_vm.user.displayName(teacher.first_name, teacher.last_name)} [${
                teacher.email
              }]`,"value":teacher.id}})}),1)],1),_c('el-form-item',{attrs:{"label":"Introduction"}},[_c('el-input',{attrs:{"type":"textarea","rows":3},model:{value:(_vm.newCompany.introduction),callback:function ($$v) {_vm.$set(_vm.newCompany, "introduction", $$v)},expression:"newCompany.introduction"}})],1)],1),_c('span',{staticClass:"dialog-footer",attrs:{"slot":"footer"},slot:"footer"},[_c('el-button',{on:{"click":function($event){_vm.showCompanyModal = false}}},[_vm._v("Cancel")]),_c('el-button',{attrs:{"type":"success"},on:{"click":_vm.crateNewCompany}},[_vm._v("Create")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }